/* eslint-disable no-unused-vars */
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { CustomTitle, AddEmployeeStyledModal, Tile, CustomDropdown, CustomMenuItemEmployee, TitleContainerEmployee, AddtoTeamButton, TileWraper, SearchIcon, SearchInput, DropdownWrapper, NewCustomDropdown } from './styles';
import { Modal } from 'react-bootstrap';
import { ImageUrl } from "../../utils/constants";
import { connect } from "react-redux";
import {
  fetchUsersToAddToTeam,
  fetchAddTeamMembersData,
} from "../../redux/actions";
import _ from "lodash";
import Waiting from "../Waiting";
import LazyImage from "../common/LazyImage/LazyImage";

const ENTER_KEY = 13;

class AddUsersToTeamPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pointsDrop: false,
      departmentDrop: false,
      locationDrop: false,
      addButtondisabled: true,
      pointsData: [1, 2, 3, 4],
      selectedDepartment: null,
      selectedLocation: null,
      selectedPoints: "Points",
      departmentId: null,
      locationId: null,
      employeeSelected: false,
      employeeSelectedId: null,
      selectedEmployeeData: [],
      search: null,
    };
  }

  updateDropdown = (title, id, titleKey, idKey) => {
    this.setState(
      {
        [titleKey]: title,
        [idKey]: id,
      },
      () => {
        this.viewUsersToAdd();
      }
    );
  };

  viewUsersToAdd = () => {
    const { fetchUsersToAddToTeam, companyId } = this.props;
    const {
      selectedDepartment,
      selectedLocation,
      departmentId,
      locationId,
      search,
    } = this.state;
    let obj = {};
    obj["company_id"] = companyId;
    if (selectedDepartment) {
      obj["department_id"] = departmentId;
    }
    if (selectedLocation) {
      obj["city_state_id"] = locationId;
    }
    if (search) {
      obj["name"] = search;
    }
    fetchUsersToAddToTeam(obj);
  };

  onSelect = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  enableSelectedEmployee = (uid, status) => {
    let activeSelctedEmployee = [];
    if (status) {
      activeSelctedEmployee.push(uid);
      this.setState({
        selectedEmployeeData: this.state.selectedEmployeeData.concat(
          activeSelctedEmployee
        ),
      });
    } else {
      let newSelected = this.state.selectedEmployeeData.filter(
        (obj) => obj != uid
      );
      this.setState({
        selectedEmployeeData: newSelected,
      });
    }
  };

  submitTeamUsersToAdd = () => {
    const { teamId, hideModal } = this.props;
    const { selectedEmployeeData } = this.state;
    let obj = {};
    obj["team_id"] = teamId;
    obj["uids"] = selectedEmployeeData;
    this.postTeamUsersToAdd(obj);
    hideModal();
  };

  postTeamUsersToAdd = (obj) => {
    const { fetchAddTeamMembersData, pageNo, limit, companyId, teamId } =
      this.props;
    fetchAddTeamMembersData(obj, companyId, pageNo, limit, teamId);
    this.setState({
      selectedEmployeeData: [],
      selectedDepartment: null,
      selectedLocation: null,
    });
  };

  fallBack = (e) => {
    e.target.src = "/images/default.png";
  };

  hideModalData = () => {
    const { hideModal } = this.props;
    this.setState({
      selectedEmployeeData: [],
      selectedDepartment: null,
      selectedLocation: null,
    });
    hideModal();
  };

  onChange = (e) => {
    this.setState(
      {
        search: e.target.value,
      },
      () => {
        this.viewUsersToAdd();
      }
    );
  };

  render() {
    const {
      showModal,
      teamUsers,
      departmentDetails,
      locationDetails /*memberCount, teamMembersCount*/,
    } = this.props;
    const {
      departmentDrop,
      locationDrop,
      selectedDepartment,
      selectedLocation,
      selectedEmployeeData,
    } = this.state;
    // let enableDisableAddButton=0;
    // enableDisableAddButton = teamMembersCount+selectedEmployeeData.length;
    return (
      <Suspense fallback={<Waiting />}>
        <AddEmployeeStyledModal
          show={showModal}
          // onHide={hideModal}
          dialogClassName="modal-90w"
        >
          <Modal.Header>
            <Suspense fallback={<Waiting />}>
              <CustomTitle>
                <div>
                  <span>{"Select Employees To Add In The Team"}</span>
                  <span
                    className="closeImage"
                    onClick={() => this.hideModalData()}
                  >
                    <img src="/public/images/new_close_icon.png" />
                  </span>
                </div>
              </CustomTitle>
            </Suspense>
          </Modal.Header>
          <Modal.Body>
            <DropdownWrapper
              scroll={
                this.props.departmentDetails &&
                !_.isNull(this.props.departmentDetails) &&
                this.props.departmentDetails.length > 15
                  ? 1
                  : 0
              }
            >
              <TitleContainerEmployee>
                <div className="searchDiv">
                  <SearchIcon>
                    <img src="/public/images/searchIcon.png" alt="Search" />
                  </SearchIcon>
                  <SearchInput
                    type="text"
                    placeholder={"Find a Employee"}
                    onChange={(e) => this.onChange(e)}
                    // onKeyDown={this.handleKeyDown}
                  />
                </div>
              </TitleContainerEmployee>
              {departmentDetails && departmentDetails.length > 1 && (
                <TitleContainerEmployee fullWidth>
                  <NewCustomDropdown
                    title={
                      this.state.selectedDepartment ? (
                        <div className="title" style={{fontFamily:"Rubik-Regular"}}> {selectedDepartment} </div>
                      ) : (
                        <div className="title" style={{fontFamily:"Rubik-Regular"}}>Department</div>
                      )
                    }
                    id="dropdown-recurring"
                    onClick={() =>
                      this.setState({ departmentDrop: !departmentDrop })
                    }
                    onSelect={(index, key) =>
                      this.updateDropdown(
                        departmentDetails[index].department,
                        departmentDetails[index].id,
                        "selectedDepartment",
                        "departmentId"
                      )
                    }
                  >
                    {departmentDetails && departmentDetails.length > 0
                      ? departmentDetails.map((item, index) => (
                        <CustomMenuItemEmployee
                          eventKey={index}
                          key={index}
                          active={this.state.selectedDepartment == item}
                          value={item}
                        >
                          {item.department}
                        </CustomMenuItemEmployee>
                      ))
                      : null}
                  </NewCustomDropdown>
                </TitleContainerEmployee>
              )}
              {locationDetails && locationDetails.length > 1 && (
                <TitleContainerEmployee fullWidth>
                  <NewCustomDropdown
                    title={
                      selectedLocation ? (
                        <div className="title" style={{fontFamily:"Rubik-Regular"}}> {selectedLocation}</div>
                      ) : (
                        <div className="title" style={{fontFamily:"Rubik-Regular"}}>Locations</div>
                      )
                    }
                    id="dropdown-recurring"
                    onClick={() =>
                      this.setState({ locationDrop: !locationDrop })
                    }
                    onSelect={(index, key) =>
                      this.updateDropdown(
                        locationDetails[index].location,
                        locationDetails[index].id,
                        "selectedLocation",
                        "locationId"
                      )
                    }
                  >
                    {locationDetails && locationDetails.length > 0
                      ? locationDetails.map((item, index) => (
                        <CustomMenuItemEmployee
                          eventKey={index}
                          key={index}
                          active={this.state.selectedLocation == item}
                        >
                          {item.location}
                        </CustomMenuItemEmployee>
                      ))
                      : null}
                  </NewCustomDropdown>
                </TitleContainerEmployee>
              )}
              {/* <TitleContainerEmployee fullWidth>
              <CustomDropdown
                title={<div className="title">{selectedPoints}</div>}
                id="dropdown-recurring"
                onClick={() => this.setState({ pointsDrop: !pointsDrop })}
              >
                {this.state.pointsData.length > 0 ? this.state.pointsData.map((item, index) => <CustomMenuItemEmployee
                  eventKey={index}
                  key={index}
                  onSelect={() => this.onSelect('selectedPoints', item)}
                  active={selectedPoints == item}
                >{item}
                </CustomMenuItemEmployee>)
                  : null}
              </CustomDropdown>
            </TitleContainerEmployee> */}
            </DropdownWrapper>
            {teamUsers.length ? (
              <TileWraper padding="1">
                {teamUsers.map((member, index) => (
                  <Tile key={index}>
                    <LazyImage
                      onError={this.fallBack}
                      src={`${ImageUrl}/${member.profile_image}`}
                      alt="people_icon"
                    />
                    <div className="textBox">
                      <h1>{member.fullname}</h1>
                      <h2>{member.department}</h2>
                      {member.location !== "null" && <h2>{member.location}</h2>}
                    </div>
                    {selectedEmployeeData.includes(member.uid) ? (
                      <div
                        className="radioButton"
                        onClick={() =>
                          this.enableSelectedEmployee(member.uid, false)
                        }
                      >
                        <img src="/public/images/whitetick.svg" />
                      </div>
                    ) : (
                      <div
                        className="radioButton"
                        onClick={() =>
                          this.enableSelectedEmployee(member.uid, true)
                        }
                      />
                    )}
                  </Tile>
                ))}
              </TileWraper>
            ) : (
              <h1>No users present in this company</h1>
            )}
          </Modal.Body>
          <Modal.Footer>
            <AddtoTeamButton
              disabled={selectedEmployeeData.length === 0}
              onClick={
                selectedEmployeeData.length === 0
                  ? ""
                  : () => this.submitTeamUsersToAdd()
              }
            >
              Add to Team
            </AddtoTeamButton>
          </Modal.Footer>
        </AddEmployeeStyledModal>
      </Suspense>
    );
  }
}

AddUsersToTeamPopup.propTypes = {
  history: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
  hideModal: PropTypes.func,
  teamUsers: PropTypes.array,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
  companyId: PropTypes.number,
  fetchUsersToAddToTeam: PropTypes.func,
  fetchAddTeamMembersData: PropTypes.func,
  teamId: PropTypes.number,
  teamDetailsData: PropTypes.array,
  pageNo: PropTypes.number,
  limit: PropTypes.number,
  memberCount: PropTypes.number,
  teamMembersCount: PropTypes.number,
};

const mapStateToProps = (state) => ({
  locationDetails: state.companyDashboard.companyPeopleLocation,
  departmentDetails: state.companyDashboard.companyPeopleDepartment,
  teamUsers: state.adminTeamCreation.teamUsers,
  teamDetailsData: state.adminTeamCreation.teamDetailsData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersToAddToTeam: (object) => dispatch(fetchUsersToAddToTeam(object)),
  fetchAddTeamMembersData: (object, companyId, pageNo, limit, teamId) =>
    dispatch(fetchAddTeamMembersData(object, companyId, pageNo, limit, teamId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUsersToTeamPopup);
